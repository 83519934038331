import React from "react";
import check from "../../assets/svgs/check.svg";
import PrimaryButton from "../../components/PrimaryButton";
import Section from "../../components/Section";
import { useHistory } from "react-router-dom";
import { goToPrivatePage } from "../../utils";
import useWindowDimensions from "../../hooks/useWindowDimensions";
const HowItWorks = () => {
  const history = useHistory();
  const { height, width } = useWindowDimensions();

  const goToSurvey = () => {
    goToPrivatePage({
      history,
      url: "/onboarding/survey",
      warningText:
        "You already took the survey, this service can't be done twice.",
    });
  };

  const handleContactUs = () => {
    history.push("/contact-us");
  };

  return (
    <>
      <Section className="flex justify-center bg-howItWorks md:bg-clouds bg-no-repeat bg-center overflow-x-hidden">
        <div className="max-w-xl m-auto text-center mt-16 mb-28">
          <h1
            className="text-3xl md:text-4xl font-bold mb-6"
            data-aos="fade-up"
            data-aos-duration="700"
          >
            Start Your Journey Towards a Better Night’s Sleep
          </h1>
          <p
            className="mb-12 font-body text-xl2 md:text-3xl"
            data-aos="fade-up"
            data-aos-duration="700"
            data-aos-delay="100"
          >
            A better night's sleep in 3 easy steps
          </p>
          <PrimaryButton
            onClick={goToSurvey}
            data-aos="fade-up"
            data-aos-duration="700"
            data-aos-delay="200"
          >
            Get Started
          </PrimaryButton>
        </div>
      </Section>
      <Section className="bg-renuma-violet-100 overflow-x-hidden">
        <div className="max-w-7xl m-auto flex flex-col md:flex-row">
          <div className="md:w-1/2 order-2 md:order-1">
            <StepHeaderTitleContainer>
              <div data-aos="fade-left" data-aos-duration="700">
                <StepNumber>Step 1</StepNumber>
                <StepTitle>Online Sleep Survey</StepTitle>
              </div>
            </StepHeaderTitleContainer>
            <StepText>
              <div
                data-aos="fade-left"
                data-aos-duration="700"
                data-aos-delay="100"
              >
                Take the online sleep survey to help determine if you are a good
                candidate for a home sleep study
              </div>
            </StepText>
            <div
              className="mb-12"
              data-aos="fade-left"
              data-aos-duration="700"
              data-aos-delay="150"
            >
              <StepList
                data={[
                  "See if a home sleep study is study is right for you",
                  "Optional telemedicine consultation with a sleep doctor for $15",
                ]}
              />
            </div>
            <PrimaryButton
              onClick={goToSurvey}
              data-aos="fade-left"
              data-aos-duration="700"
              data-aos-delay="200"
            >
              Begin Sleep Evaluation
            </PrimaryButton>
          </div>
          <div className="h-80 md:h-auto md:w-1/2 pl-0 md:pl-16 order-1 md:order-2 -mt-14 md:-mt-24 mb-8 md:mb-0">
            <div
              className="bg-howItWorks01 bg-no-repeat bg-cover w-full h-full"
              data-aos="fade-up"
              data-aos-duration="700"
            ></div>
          </div>
        </div>
      </Section>
      <Section className="bg-white md:bg-renuma-violet-300 overflow-x-hidden">
        <div className="max-w-7xl m-auto flex flex-col md:flex-row">
          <div className="h-80 md:h-auto md:w-1/2 pr-0 md:pr-16 -mt-14 md:-mt-24 mb-8 md:mb-0">
            <div
              className="bg-howItWorks02 bg-no-repeat bg-cover w-full h-full md:bg-center"
              data-aos="fade-up"
              data-aos-duration="700"
            ></div>
          </div>
          <div className="md:w-1/2 order-1">
            <StepHeaderTitleContainer>
              <div data-aos="fade-left" data-aos-duration="700">
                <StepNumber>Step 2</StepNumber>
                <StepTitle>Home Sleep Study</StepTitle>
              </div>
            </StepHeaderTitleContainer>
            <StepText>
              <div
                data-aos="fade-left"
                data-aos-duration="700"
                data-aos-delay="150"
              >
                The home sleep study for sleep apnea monitors your sleep from
                the comfort of your home. It includes sleep testing equipment
                and a consultation with a sleep doctor to review the results.
              </div>
            </StepText>
            <div
              data-aos="fade-left"
              data-aos-duration="700"
              data-aos-delay="200"
            >
              <StepList
                data={[
                  "Test from the comfort of your own bed",
                  "Get results in 3 days or less",
                ]}
              />
            </div>
          </div>
        </div>
      </Section>
      <Section className="bg-renuma-violet-300 md:bg-white overflow-x-hidden">
        <div className="max-w-7xl m-auto flex flex-col md:flex-row">
          <div className="md:w-1/2 order-1 md:order-1">
            <StepHeaderTitleContainer>
              <div data-aos="fade-left" data-aos-duration="700">
                <StepNumber>Step 3</StepNumber>
                <StepTitle>Ongoing care with Renuma+</StepTitle>
              </div>
            </StepHeaderTitleContainer>
            <StepText>
              <div
                data-aos="fade-left"
                data-aos-duration="700"
                data-aos-delay="150"
              >
                When you sign up for Renuma+, you’ll receive sleep apnea treatment and
                coordinated ongoing monitoring to ensure successful control
                (including $15 telemedicine consultations with a sleep doctor if
                needed)
              </div>
            </StepText>
            <div
              className="mb-14"
              data-aos="fade-left"
              data-aos-duration="700"
              data-aos-delay="200"
            >
              <StepList
                data={[
                  "Treatment delivered to your door",
                  "Ongoing monitoring of treatment",
                ]}
              />
            </div>
             {(width >= 771) && <PrimaryButton
              className="mb-14"
              onClick={goToSurvey}
              data-aos="fade-left"
              data-aos-duration="700"
              data-aos-delay="250"
            >
              Get Started
            </PrimaryButton>}
          </div>
          <div className="h-20 md:h-auto md:w-1/2 pl-0 md:pl-16 order-2 md:order-2 md:mt-0 mb-8 md:mb-0">
            {/*<div
              className="bg-howItWorks03 bg-no-repeat bg-contain w-full h-full mb-8"
              data-aos="fade-up"
              data-aos-duration="700"
            ></div>*/}
            {(width < 771) && <PrimaryButton
              onClick={goToSurvey}
              data-aos="fade-left"
              data-aos-duration="700"
              data-aos-delay="250"
            >
              Get Started
            </PrimaryButton>}
          </div>
        </div>
      </Section>
      <Section className="overflow-x-hidden">
        <div className="max-w-3xl m-auto text-center">
          <div data-aos="fade-up" data-aos-duration="700">
            <StepTitle>Not Sure if Renuma is Right for you?</StepTitle>
          </div>
          <div
            className="mt-4 mb-10"
            data-aos="fade-up"
            data-aos-duration="700"
            data-aos-delay="100"
          >
            <StepText>
              We are here to answer your questions. Reach out to a customer
              service representative now.
            </StepText>
          </div>
          <PrimaryButton
            onClick={handleContactUs}
            data-aos="fade-up"
            data-aos-duration="700"
            data-aos-delay="150"
          >
            Contact Us
          </PrimaryButton>
        </div>
      </Section>
    </>
  );
};
const StepHeaderTitleContainer = ({ children }) => (
  <div className="mb-6 md:mb-10 border-b border-renuma-violet-400">
    {children}
  </div>
);
const StepNumber = ({ children }) => (
  <p className="text-renuma-violet-primary mb-2 text-lg uppercase">
    {children}
  </p>
);
const StepTitle = ({ children }) => (
  <h2 className="text-xl2 md:text-3xl font-bold">{children}</h2>
);
const StepText = ({ children }) => (
  <p className="mb-8 font-body text-base md:text-xl leading-normal">
    {children}
  </p>
);
const StepList = ({ data }) => (
  <div className="font-body space-y-6">
    {data.map((item, key) => (
      <div key={key} className="flex items-center  space-x-6 md:space-x-8">
        <img className="list-check" alt="list-check" src={check} />
        <p className="mb-0 text-base md:text-xl leading-normal">{item}</p>
      </div>
    ))}
  </div>
);
export default HowItWorks;
