import React from "react";
import PrimaryButton from "../../components/PrimaryButton";
import Section from "../../components/Section";
import bed from "../../assets/svgs/bed.svg";
import checkList from "../../assets/svgs/check-list.svg";
import clock from "../../assets/svgs/clock.svg";
import movingTruck from "../../assets/svgs/moving-truck.svg";
import ongoing from "../../assets/svgs/ongoing.svg";
import stethoscope from "../../assets/svgs/stethoscope.svg";
import manyoptions from "../../assets/svgs/many-options.svg";

import { Button } from "antd";
import { useHistory } from "react-router-dom";
import { goToPrivatePage } from "../../utils";

const Pricing = () => {
  const history = useHistory();

  const goToSurvey = () => {
    goToPrivatePage({
      history,
      url: "/onboarding/survey",
      warningText:
        "You already took the survey, this service can't be done twice.",
    });
  };

  return (
    <>
      <Section className="text-center mt-10 md:mt-10">
        <h1
          className="text-3xl md:text-4xl font-bold -mx-2 md:mx-0 leading-snug md:leading-normal mb-6"
          data-aos="fade-up"
          data-aos-duration="700"
          data-aos-delay="0"
        >
          Transparent Pricing,
          <br />
          Convenient At-Home Treatment
        </h1>
        <p
          className="font-body text-xl2 md:text-2xl"
          data-aos="fade-up"
          data-aos-duration="700"
          data-aos-delay="100"
        >
          Free deliveries and no hidden fees
        </p>
      </Section>
      <Section className="-mt-28">
        <div className="max-w-7xl m-auto grid grid-cols-1 md:grid-cols-3 md:gap-x-6 gap-y-14 md:gap-y-0 mb-16">
          <div
            data-aos="fade-up"
            data-aos-duration="700"
            data-aos-delay="150"
            className="flex flex-column justify-center"
          >
            <Step className="h-full">
              <div className="flex flex-col h-full">
                <StepPillLabel>Step 1</StepPillLabel>
                <StepHeader>Online Sleep Survey</StepHeader>
                <div className="flex flex-1 flex-col justify-between">
                  <div className="flex items-center mb-6 space-x-4">
                    <img alt="exam" src={checkList} />
                    <p className="text-base md:text-lg leading-normal mb-0">
                      Determine if a home sleep study is right for you*
                    </p>
                  </div>
                  <div className="flex flex-col items-center">
                    <p className="text-center font-bold text-2xl md:text-3xl font-body mb-4">
                      $0
                    </p>
                    <p className="text-center text-sm leading-relaxed font-body mb-3.5 md:mb-6 text-gray-800">
                      *Optional telemedicine sleep doctor consultation for $15
                    </p>
                  </div>
                </div>
              </div>
            </Step>
          </div>
          <div
            data-aos="fade-up"
            data-aos-duration="700"
            data-aos-delay="200"
            className="flex flex-column justify-center"
          >
            <Step>
              <div>
                <StepPillLabel>Step 2</StepPillLabel>
                <StepHeader>Home Sleep Test</StepHeader>
                <StepBody>
                  <div className="flex items-center mb-6 space-x-4">
                    <img alt="bed" src={bed} />
                    <p className="text-base md:text-lg leading-normal mb-0">
                      Test from the comfort of your own bed
                    </p>
                  </div>
                  <div className="flex items-center mb-6 space-x-4">
                    <img alt="clock" src={clock} />
                    <p className="text-base md:text-lg leading-normal mb-0">
                      Get results in 3 days or less
                    </p>
                  </div>
                </StepBody>
                <p className="text-center font-bold text-2xl md:text-3xl font-body mb-4">
                  $199
                </p>
                <p className="text-center text-sm leading-relaxed font-body mb-3.5 md:mb-6 text-gray-800">
                  Includes telemedicine visit with a sleep doctor to review
                  study results
                </p>
              </div>
            </Step>
          </div>
          <div
            data-aos="fade-up"
            data-aos-duration="700"
            data-aos-delay="250"
            className="flex flex-column justify-center"
          >
            <Step>
              <div>
                <StepPillLabel>Step 3</StepPillLabel>
                <StepHeader>Ongoing Care with Renuma+</StepHeader>
                <StepBody>
                  <div className="flex items-center mb-6 space-x-4">
                    <img alt="moving truck" src={movingTruck} />
                    <p className="text-base md:text-lg leading-normal mb-0">
                      Supplies delivered to your door
                    </p>
                  </div>
                  <div className="flex items-center mb-6 mr-6 space-x-4">
                    <img alt="ongoing" src={ongoing} />
                    <p className="text-base md:text-lg leading-normal mb-0">
                      Ongoing monitoring of treatment*
                    </p>
                  </div>
                  <div className="flex items-center mb-6 mr-6 space-x-4">
                    <img alt="ongoing" src={manyoptions} width="60" height="60" />
                    <p className="text-base md:text-lg leading-normal mb-0">
                      Many treatment options available
                    </p>
                  </div>

                </StepBody>
                {/*<p className="text-center font-bold text-2xl md:text-3xl font-body mb-4">
                  $30-$100/month
                </p>
                <p className="text-center text-sm leading-relaxed font-body mb-3.5 md:mb-6 text-gray-800">
                  (For the first 10 months, you pay $100/month After 10 months,
                  continue for $30/month)
                </p>*/}
                {/*<div
                  className="bg-howItWorks03 bg-no-repeat bg-contain w-full h-full mb-8"
                  data-aos="fade-up"
                  data-aos-duration="700"
                ></div>*/}
              </div>
            </Step>
          </div>
        </div>
        <div
          className="flex justify-center"
          data-aos="fade-up"
          data-aos-duration="700"
          data-aos-delay="0"
        >
          <PrimaryButton onClick={goToSurvey}>Get Started</PrimaryButton>
        </div>
      </Section>
    </>
  );
};
const Step = ({ children }) => (
  <div className="bg-gray-100 px-9 pb-13r md:pb-12r pt-16 md:pt-20 rounded relative flex flex-col justify-between">
    {children}
  </div>
);
const StepHeader = ({ children }) => (
  <h2 className="text-lg md:text-xl text-center font-bold mb-12 md:mb-14 border-b border-blue-100">
    {children}
  </h2>
);
const StepBody = ({ children }) => (
  <div className="mb-12 md:mb-16">{children}</div>
);
const StepPillLabel = ({ children }) => (
  <p className="absolute -top-6 left-0 right-0 m-auto text-center w-36 py-2 mb-0 text-lg text-white rounded-full bg-secondary uppercase">
    {children}
  </p>
);

export default Pricing;
