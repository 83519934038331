import React from "react";
import check from "../../assets/svgs/check.svg";
import moving_truck from "../../assets/svgs/moving-truck.svg";
import ongoing from "../../assets/svgs/ongoing.svg";
import PrimaryButton from "../../components/PrimaryButton";
import Section from "../../components/Section";
import { useHistory } from "react-router-dom";
import { goToPrivatePage } from "../../utils";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import renumaplus01 from "../../assets/images/renuma-plus-01.png";
import renumaplus02 from "../../assets/images/renuma-plus-02.png";
import renumaplus03 from "../../assets/images/renuma-plus-03.png";
import renumaplus04 from "../../assets/images/renuma-plus-04.jpg";
import renumaplus05_1 from "../../assets/images/renuma-plus-05-1.png";
import renumaplus05_2 from "../../assets/images/renuma-plus-05-2.png";
import renumaplus06 from "../../assets/images/renuma-plus-06.png";
import renumaplus07 from "../../assets/images/renuma-plus-07.png";

const RenumaPlus = () => {
  const history = useHistory();
  const { height, width } = useWindowDimensions();
  const goToSurvey = () => {
    goToPrivatePage({
      history,
      url: "/onboarding/survey",
      warningText:
        "You already took the survey, this service can't be done twice.",
    });
  };

  const handleContactUs = () => {
    history.push("/contact-us");
  };

  return (
    <>
      <Section className="flex justify-center bg-howItWorks md:bg-clouds bg-no-repeat bg-center overflow-x-hidden">
        <div className="max-w-xl m-auto text-center mt-16 mb-28">
          <h1
            className="text-3xl md:text-4xl font-bold mb-6"
            data-aos="fade-up"
            data-aos-duration="700"
          >
            Renuma+ Helps Connect You With All Sleep Apnea Treatments
          </h1>
          <p
            className="mb-12 font-body text-xl2 md:text-3xl"
            data-aos="fade-up"
            data-aos-duration="700"
            data-aos-delay="100"
          >
            No matter the treatment you choose, we help make sure it is delivered to your door in a timely fashion and provide ongoing monitoring of the treatment effectiveness for your sleep apnea.
          </p>
          <PrimaryButton
            onClick={() => {
              const section = document.getElementById('options');
              let scrollY = section.offsetTop;
              const navHeight = document.getElementById('navbar');
              if (navHeight) {
                scrollY -= navHeight.offsetHeight * 2;
              }
              window.scrollTo({
                top: scrollY, // Subtract the height of the nav
                behavior: 'smooth',
              });
            }}
            data-aos="fade-up"
            data-aos-duration="700"
            data-aos-delay="200"
          >
            See The Various Options Below
          </PrimaryButton>
        </div>
      </Section>
      <Section className="bg-renuma-violet-100 overflow-x-hidden">
        <div id="options" className="max-w-7xl m-auto flex flex-col md:flex-row items-center">
          <div className="md:w-1/2 order-2 md:order-1">
            <StepHeaderTitleContainer>
              <div data-aos="fade-left" data-aos-duration="700">
                <StepNumber>Option 1</StepNumber>
                <StepTitle>Renuma+PAP</StepTitle>
              </div>
            </StepHeaderTitleContainer>
            <StepText>
              <div
                data-aos="fade-left"
                data-aos-duration="700"
                data-aos-delay="100"
              >
                Everything you need to succeed with PAP therapy all in one place
              </div>
            </StepText>
            <div
              className="mb-12"
              data-aos="fade-left"
              data-aos-duration="700"
              data-aos-delay="150"
            >
              <StepList
                icons={[moving_truck, ongoing]}
                data={[
                  "Supplies delivered to your door",
                  "Ongoing monitoring of treatment*",
                ]}
              />
            </div>
          </div>
          <div
            className="h-80 md:h-fit md:w-1/2 pl-0 order-1 md:order-2 -mt-14 md:my-auto md:mt-0 md:mb-0 mb-8 flex items-center justify-center"
            data-aos="fade-up"
            data-aos-duration="700"
          >
            <img
              className="h-full"
              src={renumaplus01}
              alt="Renuma+BongoRx"
            />
          </div>
        </div>
      </Section>
      <Section className="bg-white md:bg-renuma-violet-300 overflow-x-hidden">
        <div className="max-w-7xl m-auto flex flex-col md:flex-row ">
          <div
            className="h-80 md:h-fit md:w-1/2 pr-0 md:pr-16 -mt-14 md:mt-0 mb-8 md:mb-0 flex items-center justify-center"
            data-aos="fade-up"
            data-aos-duration="700"
          >
            <img
              className="h-full"
              src={renumaplus02}
              alt="Renuma+BongoRx"
            />
          </div>
          <div className="md:w-1/2 order-1">
            <StepHeaderTitleContainer>
              <div data-aos="fade-left" data-aos-duration="700">
                <StepNumber>Option 2</StepNumber>
                <StepTitle>Renuma+BongoRx</StepTitle>
              </div>
            </StepHeaderTitleContainer>
            <div
              data-aos="fade-left"
              data-aos-duration="700"
              data-aos-delay="200"
            >
              <StepList
                data={[
                  "An affordable alternative to CPAP",
                  "$200 one-time fee",
                  "We will help monitor the effectiveness of this treatment",
                ]}
              />
            </div>
          </div>
        </div>
      </Section>
      <Section className="bg-renuma-violet-300 md:bg-white overflow-x-hidden">
        <div className="max-w-7xl m-auto flex flex-col md:flex-row items-center">
          <div className="md:w-1/2 order-2 md:order-1">
            <StepHeaderTitleContainer>
              <div data-aos="fade-left" data-aos-duration="700">
                <StepNumber>Option 3</StepNumber>
                <StepTitle>Renuma+OAT</StepTitle>
              </div>
            </StepHeaderTitleContainer>
            <div
              className="mb-14"
              data-aos="fade-left"
              data-aos-duration="700"
              data-aos-delay="200"
            >
              <StepList
                data={[
                  "We will work with preferred providers to help you obtain an oral appliance",
                  "We will help monitor the effectiveness of the appliance",
                ]}
              />
            </div>
          </div>
          <div
            className="h-80 md:h-fit md:w-1/2 pl-0 order-1 md:order-2 -mt-14 md:my-auto md:mt-0 md:mb-0 mb-8 flex items-center justify-center"
            data-aos="fade-up"
            data-aos-duration="700"
          >
            <img
              className="h-full"
              src={renumaplus03}
              alt="Renuma+BongoRx"
            />
          </div>
        </div>
      </Section>
      <Section className="bg-white md:bg-renuma-violet-300 overflow-x-hidden">
        <div className="max-w-7xl m-auto flex flex-col md:flex-row">
          <div
            className="h-80 md:h-fit md:w-1/2 pr-0 md:pr-16 -mt-14 md:mt-0 mb-8 md:mb-0 flex items-center justify-center"
            data-aos="fade-up"
            data-aos-duration="700"
          >
            <img
              className="h-full"
              src={renumaplus04}
              alt="Renuma+BongoRx"
            />
          </div>
          <div className="md:w-1/2 order-1">
            <StepHeaderTitleContainer>
              <div data-aos="fade-left" data-aos-duration="700">
                <StepNumber>Option 4</StepNumber>
                <StepTitle>Renuma+GLP-1</StepTitle>
              </div>
            </StepHeaderTitleContainer>
            <StepText>
              <div
                data-aos="fade-left"
                data-aos-duration="700"
                data-aos-delay="100"
              >
                GLP-1 therapy is a newly proven treatment for OSA
              </div>
            </StepText>
            <div
              data-aos="fade-left"
              data-aos-duration="700"
              data-aos-delay="200"
            >
              <StepList
                data={[
                  "We will connect you with preferred providers to start GLP-1 treatment if indicated",
                  "We will help monitor the improvement in your sleep apnea with weight loss",
                ]}
              />
            </div>
          </div>
        </div>
      </Section>
      <Section className="bg-renuma-violet-300 md:bg-white overflow-x-hidden">
        <div className="max-w-7xl m-auto flex flex-col md:flex-row">
          <div className="md:w-1/2 order-2 md:order-1">
            <StepHeaderTitleContainer>
              <div data-aos="fade-left" data-aos-duration="700">
                <StepNumber>Option 5</StepNumber>
                <StepTitle>Renuma+ExciteOSA</StepTitle>
              </div>
            </StepHeaderTitleContainer>
            <StepText>
              <div
                data-aos="fade-left"
                data-aos-duration="700"
                data-aos-delay="100"
              >
                A device that uses electrical impulses for 20 minutes a day to help make the airway less collapsible in sleep
              </div>
            </StepText>
            <div
              className="mb-14"
              data-aos="fade-left"
              data-aos-duration="700"
              data-aos-delay="200"
            >
              <StepList
                data={[
                  "$1600 One-Time Fee",
                  "We will help monitor the effectiveness of the treatment for your sleep apnea",
                ]}
              />
            </div>
          </div>
          <div
            className="md:h-fit w-full md:w-1/2 pl-0 order-1 md:order-2 -mt-14 md:my-auto md:mt-0 md:mb-0 mb-8 flex flex-row md:flex-row items-center justify-between"
            // style={{ gap: "1rem" }}
            data-aos="fade-up"
            data-aos-duration="700"
          >
            <img
              className="w-1/2"
              src={renumaplus05_1}
              alt="Renuma+BongoRx"
            />
            <img
              className="w-1/2"
              src={renumaplus05_2}
              alt="Renuma+BongoRx"
            />
          </div>
        </div>
      </Section>
      <Section className="bg-white md:bg-renuma-violet-300 overflow-x-hidden">
        <div className="max-w-7xl m-auto flex flex-col md:flex-row">
          <div
            className="h-80 md:h-fit md:w-1/2 pr-0 md:pr-16 -mt-14 md:mt-0 mb-8 md:mb-0 flex items-center justify-center"
            data-aos="fade-up"
            data-aos-duration="700"
          >
            <img
              className="h-fit"
              src={renumaplus06}
              alt="Renuma+BongoRx"
            />
          </div>
          <div className="md:w-1/2 order-1">
            <StepHeaderTitleContainer>
              <div data-aos="fade-left" data-aos-duration="700">
                <StepNumber>Option 6</StepNumber>
                <StepTitle>Renuma+Positional Therapy</StepTitle>
              </div>
            </StepHeaderTitleContainer>
            <StepText>
              <div
                data-aos="fade-left"
                data-aos-duration="700"
                data-aos-delay="100"
              >
                A device that restricts you from turning on your back if you have sleep apnea that is limited to your back.
                Your Renuma-affiliated sleep doctor will provide guidance on which device works best for you.
              </div>
            </StepText>
            <div
              className="mb-14"
              data-aos="fade-left"
              data-aos-duration="700"
              data-aos-delay="200"
            >
              <StepList
                data={[
                  "We will help monitor the effectiveness of the treatment for your sleep apnea",
                ]}
              />
            </div>
          </div>
        </div>
      </Section>
      <Section className="bg-renuma-violet-300 md:bg-white overflow-x-hidden">
        <div className="max-w-7xl m-auto flex flex-col md:flex-row">
          <div className="md:w-1/2 order-2 md:order-1">
            <StepHeaderTitleContainer>
              <div data-aos="fade-left" data-aos-duration="700">
                <StepNumber>Option 7</StepNumber>
                <StepTitle>Renuma+Sleep Surgery</StepTitle>
              </div>
            </StepHeaderTitleContainer>
            <StepText>
              <div
                data-aos="fade-left"
                data-aos-duration="700"
                data-aos-delay="100"
              >
                There are various surgical procedures, including an implantable device for treating sleep apnea.
                Your Renuma-affiliated sleep doctor can provide you with guidance on whether this is a good option for you and help connect you with a sleep surgeon.
              </div>
            </StepText>
          </div>
          <div
            className="h-80 md:h-fit md:w-1/2 pl-0 order-1 md:order-2 -mt-14 md:my-auto md:mt-0 md:mb-0 mb-8 flex items-center justify-center"
            data-aos="fade-up"
            data-aos-duration="700"
          >
            <img
              className="h-full"
              src={renumaplus07}
              alt="Renuma+BongoRx"
            />
          </div>
        </div>
      </Section>
      <Section className="overflow-x-hidden">
        <div className="max-w-3xl m-auto text-center">
          <div data-aos="fade-up" data-aos-duration="700">
            <StepTitle>Not Sure if Renuma+ is Right for you?</StepTitle>
          </div>
          <div
            className="mt-4 mb-10"
            data-aos="fade-up"
            data-aos-duration="700"
            data-aos-delay="100"
          >
            <StepText>
              We are here to answer your questions. Reach out to a customer
              service representative now.
            </StepText>
          </div>
          <PrimaryButton
            onClick={handleContactUs}
            data-aos="fade-up"
            data-aos-duration="700"
            data-aos-delay="150"
          >
            Contact Us
          </PrimaryButton>
        </div>
      </Section>
    </>
  );
};
const StepHeaderTitleContainer = ({ children }) => (
  <div className="mb-6 md:mb-10 border-b border-renuma-violet-400">
    {children}
  </div>
);
const StepNumber = ({ children }) => (
  <p className="text-renuma-violet-primary mb-2 text-lg uppercase">
    {children}
  </p>
);
const StepTitle = ({ children }) => (
  <h2 className="text-xl2 md:text-3xl font-bold">{children}</h2>
);
const StepText = ({ children }) => (
  <p className="mb-8 font-body text-base md:text-xl leading-normal">
    {children}
  </p>
);
const StepList = ({ data, icons }) => (
  <div className="font-body space-y-6">
    {data.map((item, key) => (
      <div key={key} className="flex items-center space-x-6 md:space-x-8">
        <img className="list-check" alt="list-check" src={icons ? icons[key] : check} />
        <p className="mb-0 text-base md:text-xl leading-normal">{item}</p>
      </div>
    ))}
  </div>
);
export default RenumaPlus;
