import React, { useEffect, useState } from "react";
import { Layout, Menu, Dropdown, Input } from "antd";
import clsx from "clsx";
import { Link, useHistory, useLocation } from "react-router-dom";
import { MenuOutlined, CloseOutlined } from "@ant-design/icons";
import "./AnonymousNavigator.css";
import { Logo } from "./Logo";
import { UserIcon } from "./UserIcon";
import { ChevronDownIcon } from "../Icons";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { Auth } from "aws-amplify";
// App constants
import {
  AUTH_USER_TOKEN_KEY,
  USER_PROFILE,
  USER_COMPLETE_VIDEO,
  PATH_NEXT_URL,
} from "../../utils";
import { useAuth } from "../../providers/auth.context";
import PrimaryButton from "../../components/PrimaryButton";

import { goToPrivatePage } from "../../utils";

const { Content, Footer } = Layout;

const AnonymousNavigator = ({ children }) => {
  const { height, width } = useWindowDimensions();
  const cutOff = 1060;
  const fhdCutOff = 1919;
  const [menuExpanded, setMenuExpanded] = useState(false);
  const [user, setUser] = useState(null);
  const history = useHistory();
  const location = useLocation();
  const { currentUser } = useAuth();

  useEffect(() => {
    setUser(currentUser);
  }, [currentUser]);

  const currentYear = () => {
    return new Date().getFullYear();
  };

  const handleLogout = async () => {
    try {
      await Auth.signOut();
      localStorage.removeItem(AUTH_USER_TOKEN_KEY);
      localStorage.removeItem(USER_PROFILE);
      localStorage.removeItem(USER_COMPLETE_VIDEO);
      sessionStorage.removeItem(PATH_NEXT_URL);
      window.location.href = "/";
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };

  const isAuth = () => {
    return (
      location.pathname === "/login" ||
      location.pathname === "/get-an-account" ||
      location.pathname === "/forgot-password" ||
      location.pathname === "/confirmation-code" ||
      location.pathname === "/reset-password"
    );
  };

  const showFooter = () => {
    return !isAuth() || width > cutOff;
  };

  const handleMenuExpanded = (expanded) => {
    setMenuExpanded(expanded);
    if (expanded) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  };

  const goToSurvey = () => {
    goToPrivatePage({
      history,
      url: "/onboarding/survey",
      warningText:
        "You already took the survey, this service can't be done twice.",
    });
  };

  const bgHeader = () => {
    let bg = "";
    if (menuExpanded) {
      bg = "bg-renuma-violet-800";
    } else {
      bg = "bg-white bg-opacity-90";
    }
//    if (location.pathname === "/about-us") {
//      bg = "bg-renuma-violet-800";
//    } else {
//
//    }
    return bg;
  };

  const MenuItems = (
    <Menu>
      <Menu.Item key="mi_dashboard">
        <Link to="/dashboard">My Dashboard</Link>
      </Menu.Item>
      <Menu.Item key="mi_logout">
        <a onClick={handleLogout}>Log out</a>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Layout className={clsx("layout min-h-screen bg-white")}>
        <header
          id="navbar"
          className={clsx(
            width > cutOff ? "nav-desktop-header" : "nav-mobile-header",
            "w-full z-10 md:fixed",
            menuExpanded ? "fixed" : "absolute",
            bgHeader()
          )}
        >
          <div className="flex items-center justify-between py-4 w-full max-w-screen-xl mx-auto px-6">
            <div className="inline-block">
              <Link to="/">
                <Logo
                  color={
                    !menuExpanded
                      ? "#6c009e"
                      : "#FFFFFF"
                  }
                />
              </Link>
            </div>

            {width > cutOff ? (
              <>
                <div className="flex items-center gap-8">
                  <NavLink path="/how-it-works">How it Works</NavLink>
                  <NavLink path="/renuma-plus">Renuma Plus</NavLink>
                  <NavLink path="/pricing">Pricing</NavLink>
                  <NavLink path="/sleep-apnea-101">Sleep Apnea 101</NavLink>
                  <NavLink path="/faqs">FAQs</NavLink>
                  <NavLink path="/about-us">About Us</NavLink>
                  
                  <PrimaryButton type={"send_nudge"} onClick={() => {history.push('/nudge')}}>
                    NUDGE SOMEONE
                  </PrimaryButton>
                </div>
                <div className="flex">
                  {user === null && (
                    <>
                      <Link
                        className="space-x-2 flex items-center relative"
                        to="/login"
                      >
                        <UserIcon
                          color={"#6c009e"}
                        />
                        <span
                          className={clsx(
                            "hidden sm:inline-block",
                            "text-renuma-violet-primary"
                             
                          )}
                        >
                          Log In
                        </span>
                      </Link>
                    </>
                  )}
                  {user !== null && (
                    <>
                      <Dropdown overlay={MenuItems}>
                        <a
                          className="space-x-2 flex items-center relative ant-dropdown-link"
                          onClick={(e) => e.preventDefault()}
                        >
                          <UserIcon
                            color={"#6c009e"}
                          />
                          <span
                            className={clsx(
                              "hidden sm:inline-block",
                              "text-renuma-violet-primary"
                            )}
                          >
                            My Account
                          </span>
                          <ChevronDownIcon
                            color={"#6c009e"}
                          />
                        </a>
                      </Dropdown>
                    </>
                  )}
                </div>
              </>
            ) : (
              <>
                <div
                  className="mobile-menu-toggle"
                  onClick={() => handleMenuExpanded(!menuExpanded)}
                >
                  {menuExpanded ? (
                    <CloseOutlined className={clsx("text-2xl", "text-white")} />
                  ) : (
                    <MenuOutlined
                      className={clsx(
                        "text-2xl","text-renuma-violet-800"
                      )}
                    />
                  )}
                </div>
                <div
                  className="space-y-4 bg-renuma-violet-800 nav-mobile-header-main-menu fixed z-10 top-16 w-screen left-0 pt-16"
                  onClick={() => handleMenuExpanded(false)}
                  style={{
                    display:
                      !menuExpanded && width <= cutOff ? "none" : "block",
                    height: "calc(100vh - 64px)",
                  }}
                >
                  <Link
                    className="block text-white text-xl font-bold"
                    to="/how-it-works"
                  >
                    How it Works
                  </Link>
                  <Link
                    className="block text-white text-xl font-bold"
                    to="/renuma-plus"
                  >
                    Renuma Plus
                  </Link>
                  <Link
                    className="block text-white text-xl font-bold"
                    to="/pricing"
                  >
                    Pricing
                  </Link>
                  <Link
                    className="block text-white text-xl font-bold"
                    to="/sleep-apnea-101"
                  >
                    Sleep Apnea 101
                  </Link>
                  <Link className="block text-white text-xl font-bold"
                    to="/faqs"
                  >
                    FAQs
                  </Link>
                  <Link
                    className="block text-white text-xl font-bold"
                    to="/about-us"
                  >
                    About Us
                  </Link>
                  <Link
                    className="block text-white text-xl font-bold"
                    to="/login"
                  >
                    Login
                  </Link>
                  {<Link
                    className="block text-white text-xl font-bold"
                    to="/nudge"
                  >
                    Nudge Someone
                  </Link>}
                  
                </div>
              </>
            )}
          </div>
        </header>

        <Content
          style={{
            height:
              height > 1024 && width > cutOff && isAuth()
                ? "calc(100vh - 328px)"
                : "auto",
            minHeight: height > 1024 && width > cutOff ? "" : "100vh",
          }}
        >
          {children}
        </Content>

        {showFooter() && (
          <>
            <Footer className="nav-footer pt-20 pb-14 md:pb-20 px-6 bg-gradient-to-r from-renuma-violet-800 to-renuma-violet-700">
              <div className="nav-footer-menu items-start flex md:flex-row flex-col md:justify-between md:w-full 2xl:w-2/3 container">
                <div className="nav-footer-logo md:w-1/5">
                  <Link to="/">
                    <Logo color="white" />
                    848-272-0794
                  </Link>
                </div>
                <div>
                  <div className="mb-10 flex flex-col">
                    <FooterHeader>Menu</FooterHeader>
                    <Link to="/how-it-works">How It Works</Link>
                    <Link to="/renuma-plus">Renuma Plus</Link>
                    <Link to="/pricing">Pricing</Link>
                    <Link to="/sleep-apnea-101">Sleep Apnea 101</Link>
                    <Link to="/faqs">FAQs</Link>
                    <Link to="/about-us">About Us</Link>
                  </div>
                </div>
                <div>
                  <div className="mb-10 flex flex-col">
                    <FooterHeader>Get Started</FooterHeader>
                    <a href="#" onClick={goToSurvey}>
                      Sleep Survey
                    </a>
                    {user === null && <Link to="/login">Log In</Link>}
                    {user && <PrimaryButton
                      onClick={() => {history.push('/nudge')}}
                      className="bg-white hover:text-white mt-4"
                      type="send_nudge_white">NUDGE SOMEONE</PrimaryButton>}
                  </div>
                </div>
                <div className="flex flex-col leading-8">
                  <FooterHeader>Other</FooterHeader>
                  <Link to="/contact-us">Contact Us</Link>
                  <Link to="/privacy-notice">Privacy</Link>
                  <Link to="/conditions-of-use">Terms of Use</Link>
                </div>
              </div>
            </Footer>
            <div className="bg-renuma-violet-900 py-2.5 text-renuma-violet-400 flex">
              <div className="flex md:flex-row flex-col mx-auto">
                <p className="text-sm text-center text-renuma-violet-400 m-0 leading-6">
                  Copyright © {currentYear()} Renuma. All rights reserved.
                </p>
              </div>
            </div>
          </>
        )}
      </Layout>
    </>
  );
};

const FooterHeader = (props) => (
  <p className="font-bold text-white opacity-50 text-base uppercase mb-1.5" {...props} />
);

const NavLink = ({ children, path }) => {
  const location = useLocation();
  return (
    <div className={clsx("relative")}>
      <Link
        className={clsx(
          "text-primary-text"
        )}
        to={path}
      >
        {children}
        {location.pathname === path && (
          <div
            className={clsx(
              "border-b-4 absolute w-full top-10 left-0",
              "border-renuma-violet-primary"
            )}
          ></div>
        )}
      </Link>
    </div>
  );
};
export default AnonymousNavigator;
