import React, { Fragment, useEffect, useState } from "react";
import { createBrowserHistory } from "history";
import { Router, Switch, Redirect, Route, useLocation } from "react-router-dom";
import { Auth } from "aws-amplify";
import { useAuth } from "./providers/auth.context";
import "antd/dist/antd.css";
import "./App.css";

import AnonymousNavigator from "./components/AnonymousNavigator";
import AuthenticatedNavigator from "./components/AuthenticatedNavigator";
import OnboardingNavigator from "./components/OnboardingNavigator";

import LoginScreen from "./screens/Auth/LoginScreen";
import RegisterScreen from "./screens/Auth/RegisterScreen";
import ConfirmationCodeScreen from "./screens/Auth/ConfirmationCodeScreen";
import ForgotPasswordScreen from "./screens/Auth/ForgotPasswordScreen";
import ResetPasswordScreen from "./screens/Auth/ResetPasswordScreen";

import LandingScreen from "./screens/LoggedOut/LandingScreen";
import Sleep101Screen from "./screens/LoggedOut/Sleep101Screen";
import AboutUsScreen from "./screens/LoggedOut/AboutUsScreen";
import HowItWorks from "./screens/LoggedOut/HowItWorks";
import Pricing from "./screens/LoggedOut/Pricing";
import FaqScreen from "./screens/LoggedOut/FaqScreen";
import PrivacyNoticeScreen from "./screens/LoggedOut/PrivacyNoticeScreen";
import ConditionsOfUseScreen from "./screens/LoggedOut/ConditionsOfUseScreen";
import ContactUsScreen from "./screens/LoggedOut/ContactUsScreen";
import RenumaPlus from "./screens/LoggedOut/RenumaPlus";

import OnboardingSurvey from "./screens/LoggedIn/Onboarding/Survey";
import OnboardingOrderSleepTest from "./screens/LoggedIn/Onboarding/OrderSleepTest";
import OrderCheckout from "./screens/LoggedIn/Onboarding/OrderCheckout";
import OrderSummary from "./screens/LoggedIn/Onboarding/OrderSummary";
import OnboardingTelemedicine from "./screens/LoggedIn/Onboarding/Telemedicine";
import AppointmentCheckout from "./screens/LoggedIn/Onboarding/AppointmentCheckout";
import AppointmentSummary from "./screens/LoggedIn/Onboarding/AppointmentSummary";
import ScheduleFreeConsultation from "./screens/LoggedIn/Onboarding/ScheduleFreeConsultation";
import SurveyRenumaPlus from "./screens/LoggedIn/Onboarding/SurveyRenumaPlus";
import OrderRenumaPlus from "./screens/LoggedIn/Onboarding/OrderRenumaPlus";
import OrderRenumaPlusProducts from "./screens/LoggedIn/Onboarding/OrderRenumaPlusProducts";
import OrderRenumaPlusSummary from "./screens/LoggedIn/Onboarding/OrderRenumaPlusSummary";

import HomeScreen from "./screens/LoggedIn/HomeScreen";
import OrdersScreen from "./screens/LoggedIn/OrdersScreen";
import AccountScreen from "./screens/LoggedIn/AccountScreen";
import AppointmentsScreen from "./screens/LoggedIn/AppointmentsScreen";
import SurveysScreen from "./screens/LoggedIn/SurveysScreen";
import ResultsScreen from "./screens/LoggedIn/ResultsScreen";
import SurveyDetailScreen from "./screens/LoggedIn/SurveyDetailScreen";
import ResultDetailScreen from "./screens/LoggedIn/ResultDetailScreen";
import BillingScreen from "./screens/LoggedIn/BillingScreen";
import NudgeSomeoneScreen from "./screens/LoggedIn/NudgeSomeoneScreen";

import AdminHomeScreen from "./screens/LoggedIn/AdminHomeScreen";
import AdminPatientDetails from "./screens/LoggedIn/AdminPatientDetails";
import AdminCancelationRequestsScreen from "./screens/LoggedIn/AdminCancelationRequestsScreen";
import AdminCancelationRequestsDetails from "./screens/LoggedIn/AdminCancelationRequestsDetails";

import ScrollToTop from "./components/ScrollTopTop";

// App constants
import {
  AUTH_USER_TOKEN_KEY,
  PATH_NEXT_URL,
  USER_PROFILE,
  USER_APPOINTMENT_DATA,
} from "./utils";
import InitialLoader from "./components/InitialLoader";
import Error404 from "./screens/Error/Error404";

const history = createBrowserHistory();

const App = () => {
  const [loading, setLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const { currentUser, updateCurrentUser } = useAuth();

  useEffect(() => {
    handleTokenChange();
    if (currentUser !== null) {
      setIsAdmin(currentUser.role === "admin");
    }
  }, []);

  const handleTokenChange = async () => {
    if (localStorage.getItem(AUTH_USER_TOKEN_KEY)) {
      // TODO: Check validity of token
      try {
        const user = await Auth.currentAuthenticatedUser();
        const profile = JSON.parse(localStorage.getItem(USER_PROFILE));
        const newUser = {
          email: profile.email,
          username: user.username,
          token: user.storage.accessToken,
          is_onboarded: false,
          // role: "admin",
          role: profile.role,
        };
        setIsLoggedIn(true);
        updateCurrentUser(newUser);
        setUser(newUser);
        setIsAdmin(profile.role === "admin");
      } catch (e) {
        localStorage.removeItem("accessToken");
      } finally {
        setLoading(false);
      }
    } else {
      setIsLoggedIn(false);
      updateCurrentUser(null);
      setUser(null);
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <Router history={history}>
        <ScrollToTop />
        {loading && <InitialLoader />}
        {!loading && (
          <Switch>
            <Route
              path="/dashboard"
              component={() => (
                <PrivateRoutes
                  onTokenChange={handleTokenChange}
                  userAdmin={isAdmin}
                />
              )}
            />
            <Route
              path="/onboarding"
              component={() => (
                <PrivateOnboardingRoutes onTokenChange={handleTokenChange} />
              )}
            />
            <Route
              path="/"
              component={() => (
                <PublicRoutes onTokenChange={handleTokenChange} />
              )}
            />
          </Switch>
        )}
      </Router>
    </Fragment>
  );
};

const PublicRoutes = ({ onTokenChange }) => {
  const location = useLocation();

  useEffect(() => {
    // si no está en alguno de estas url borrar datos del appointment
    const persist_in = [
      "/onboarding/telemedicine",
      "/onboarding/appointment_checkout",
      "/onboarding/appointment_confirmation",
      "/onboarding/appointment_confirmation/free",
    ];
    if (!persist_in.includes(location.pathname)) {
      localStorage.removeItem(USER_APPOINTMENT_DATA);
    }
  }, [location]);

  return (
    <AnonymousNavigator>
      <Switch>
        <Route path="/how-it-works" component={HowItWorks} />
        <Route path="/renuma-plus" component={RenumaPlus} />
        <Route path="/pricing" component={Pricing} />
        <Route path="/sleep-apnea-101" component={Sleep101Screen} />
        <Route path="/faqs" component={FaqScreen} />
        <Route path="/about-us" component={AboutUsScreen} />
        <Route path="/privacy-notice" component={PrivacyNoticeScreen} />
        <Route path="/conditions-of-use" component={ConditionsOfUseScreen} />
        <Route path="/contact-us" component={ContactUsScreen} />
        <Route path="/nudge" component={NudgeSomeoneScreen} />
        <Route
          path="/login"
          component={() => <LoginScreen onLogin={onTokenChange} />}
        />
        <Route path="/get-an-account" component={RegisterScreen} />
        <Route path="/confirmation-code" component={ConfirmationCodeScreen} />
        <Route path="/forgot-password" component={ForgotPasswordScreen} />
        <Route path="/reset-password" component={ResetPasswordScreen} />
        <Route path="/error-404" component={Error404} />
        <Route path="/" exact component={LandingScreen} />
        <Route render={() => <Redirect to="/error-404" />} />
      </Switch>
    </AnonymousNavigator>
  );
};

const PrivateRoutes = ({ onTokenChange, userAdmin }) => {
  const { currentUser } = useAuth();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    // si no está en alguno de estas url borrar datos del appointment
    const persist_in = [
      "/onboarding/telemedicine",
      "/onboarding/appointment_checkout",
      "/onboarding/appointment_confirmation",
      "/onboarding/appointment_confirmation/free",
    ];
    if (!persist_in.includes(location.pathname)) {
      localStorage.removeItem(USER_APPOINTMENT_DATA);
    }
  }, [location]);

  useEffect(() => {
    setIsAdmin(userAdmin);
    if (currentUser !== null) {
      setIsAdmin(currentUser.role === "admin");
    }
    setLoading(false);
  }, []);

  if (!currentUser) {
    sessionStorage.setItem(PATH_NEXT_URL, location.pathname);
    return <Redirect to="/login" />;
  }

  if (!location.pathname.match(/^\/dashboard/)) {
    console.log("no dashboard");
    return null;
  }

  if (loading) {
    return null;
  }

  return (
    <AuthenticatedNavigator onLogout={onTokenChange}>
      <Switch>
        {!isAdmin && (
          <>
            <Route
              path="/dashboard/surveys/:surveyId"
              component={SurveyDetailScreen}
            />
            <Route path="/dashboard/surveys" exact component={SurveysScreen} />
            <Route
              path="/dashboard/appointments"
              exact
              component={() => <AppointmentsScreen user={currentUser} />}
            />
            <Route path="/dashboard/account" exact component={AccountScreen} />
            <Route path="/dashboard/billing" exact component={BillingScreen} />
            <Route
              path="/dashboard/results/:resultId"
              component={ResultDetailScreen}
            />
            <Route path="/dashboard/results" exact component={ResultsScreen} />
            <Route
              path="/dashboard/orders"
              exact
              component={() => <OrdersScreen user={currentUser} />}
            />
            <Route path="/dashboard/" exact component={HomeScreen} />
          </>
        )}
        {isAdmin && (
          <>
            <Route
              path="/dashboard/patient-details/:email"
              component={AdminPatientDetails}
            />
            <Route path="/dashboard/" exact component={AdminHomeScreen} />
            <Route
              path="/dashboard/cancelation-requests/:email"
              exact
              component={AdminCancelationRequestsDetails}
            />
            <Route
              path="/dashboard/cancelation-requests"
              exact
              component={AdminCancelationRequestsScreen}
            />
          </>
        )}
        <Route render={() => <Redirect to="/" />} />
      </Switch>
    </AuthenticatedNavigator>
  );
};

const PrivateOnboardingRoutes = ({ onTokenChange }) => {
  const { currentUser } = useAuth();
  const location = useLocation();

  if (!currentUser) {
    sessionStorage.setItem(PATH_NEXT_URL, location.pathname);
    return <Redirect to="/get-an-account" />;
  }

  if (!location.pathname.match(/^\/onboarding/)) {
    console.log("no onboarding");
    return null;
  }

  return (
    <AnonymousNavigator onLogout={onTokenChange}>
      <Switch>
        <Route path="/onboarding/survey" component={OnboardingSurvey} />
        <Route
          path="/onboarding/order_sleep_test"
          component={OnboardingOrderSleepTest}
        />
        <Route
          path="/onboarding/order_checkout/:prod_1?/:prod_2?"
          component={OrderCheckout}
        />
        <Route
          path="/onboarding/order_renuma_plus_products"
          component={OrderRenumaPlusProducts}
        />
        <Route
          path="/onboarding/order_renuma_plus_summary"
          component={OrderRenumaPlusSummary}
        />
        <Route
          path="/onboarding/order_summary/:prod_1?"
          component={OrderSummary}
        />
        <Route
          path="/onboarding/telemedicine/:date?"
          component={OnboardingTelemedicine}
        />
        <Route
          path="/onboarding/appointment_checkout"
          component={AppointmentCheckout}
        />
        <Route
          path="/onboarding/appointment_confirmation/:type?"
          component={AppointmentSummary}
        />
        <Route
          path="/onboarding/schedule_a_consultation"
          component={ScheduleFreeConsultation}
        />
        <Route path="/onboarding/renuma_plus" component={OrderRenumaPlus} />
        <Route
          path="/onboarding/survey_renuma_plus"
          component={SurveyRenumaPlus}
        />
        <Route
          path="/onboarding/renuma_plus_checkout"
          component={SurveyDetailScreen}
        />
        <Route
          path="/onboarding/renuma_plus_confirmed"
          component={SurveyDetailScreen}
        />
        <Route render={() => <Redirect to="/" />} />
      </Switch>
    </AnonymousNavigator>
  );
};

export default App;
